import axios from 'axios';
//Import Mixpanel SDK
import mixpanel from "mixpanel-browser";
import {
    BASE_URL,
    CAPTCHA_KEY,
    CLIENT_ID,
    CLIENT_SECRET,
    NODE_STAGING,
    REACT_APP_AGREEMENT, REACT_APP_FILE_UPLOAD_URL,
    REACT_APP_MIXPANEL_TOKEN
} from "../env";

/***** staging ******/

// Near entry of your product, init Mixpanel
mixpanel.init(REACT_APP_MIXPANEL_TOKEN, {
    debug: true,
    track_pageview: true,
    persistence: "localStorage",
});

export const reposWebApi = axios.create({
    baseURL: BASE_URL
});

export const fileUploadServiceApi = axios.create({
    baseURL: REACT_APP_FILE_UPLOAD_URL
});

export const client_secret = CLIENT_SECRET;
export const client_id = CLIENT_ID;
export const agreement = REACT_APP_AGREEMENT;

export const nodeStaging = axios.create({
    // baseURL: 'http://3.6.126.216:8002/api/node'
    baseURL: NODE_STAGING
})

//****************  appVersion version and tracker for QR *****************//
export const CaptchaKey = CAPTCHA_KEY
export let appVersion = "V.4.0.9";
export const trackerForQr = 'D00158';