export const NODE_STAGING = process.env.REACT_APP_NODE_STAGING
export const CAPTCHA_KEY = process.env.REACT_APP_CAPTCHA_KEY
export const ENCRYPTION_KEY = process.env.REACT_APP_ENCRYPTION_KEY;
export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const CLIENT_SECRET = process.env.REACT_APP_CLIENT_SECRET;
export const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
export const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
export const REACT_APP_MIXPANEL_TOKEN = process.env.REACT_APP_MIXPANEL_TOKEN
export const REACT_APP_AGREEMENT = process.env.REACT_APP_AGREEMENT
export const REACT_APP_FILE_UPLOAD_URL = process.env.REACT_APP_FILE_UPLOAD_URL
