import React from 'react';
import {Redirect} from 'react-router-dom';
import PropTypes from 'prop-types';
import {useSelector} from "react-redux";

const AuthGuard = ({children}) => {
    const auth = useSelector(state => state?.session);

    if (!auth.auth && (window.location.pathname !== '/PaymentSuccess' && window.location.pathname !== '/PaymentFailed')) {
        return <Redirect to="/login"/>;
    }
    return (
        <>
            {children}
        </>
    );
};

AuthGuard.propTypes = {
    children: PropTypes.node
};

export default AuthGuard;
