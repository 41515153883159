import * as actionTypes from '../actions';
import capitalize from "@mui/material/utils/capitalize";
import secureLocalStorage from "react-secure-storage";

const userData = JSON.parse(secureLocalStorage.getItem('userDetails'));
const userLastName = JSON.parse(secureLocalStorage.getItem('user'));
const initialState = {
    loggedIn: false,
    user: {
        customerId: userData?.data?.id,
        first_name: userData?.data?.first_name,
        last_name: userLastName?.user_details?.last_name,
        email: userData?.data?.email,
        avatar: '/images/avatars/avatar_7.png',
        mobile: userData?.data?.mobile,
        all_order_count: userData?.data?.all_order_count,
        total_qty_delivered: userData?.data?.total_qty_delivered,
        role: 'USER',
    },
    last_name: userLastName?.user_details?.last_name,
    detailsOfCustomer: userData?.data,
    access_token: userLastName?.access_token,
    loginFailed: false,
    updateSuccessful: false,
    isLoading: false,
    forgotPasswordMsgSent: false,
    otpSent: false,
    signupotpSent: false,
    noUserFound: false,
    changePasswordSuccessMsg: null,
    changePasswordErrorMsg: null,
    isForgotPasswordLoading: false,
    otpStatus: false,
    signupotpStatus: false,
    auth: !!secureLocalStorage.getItem("access_token"),
    verify_user: "",
    isOTP: false,
    isForgetPass: false,
    userData: {},
    c_code: '',
    inputName: '',
    is_fp_otp: false,
    isSignupPhone: false,
    isLoginUsingPassword: false,
    isSignup: false,
    is_signup_otp: false,
    isSignupPass: false,
    is_signup_email_otp: false,
    loginPass: false,
    subcomplaintList: [],
    referralList: [],
    complaint: [],
    sessionExpired: true,
    verifysignupOtp: null,
    complaintCount: 0,
    getOtpForLoginMsg: null,
    referCount: 0,
    referNext: '',
    referralAdd: {},
    refresh: null,
    fleetAccount: [],
    fleetAccountNext: null,
    fleetAccountCount: 0,
    vanHistory: [],
    vanHistoryNext: null,
    vanHistoryCount: 0,
    vanAccountAdd: {},
    fleetOtpSent: false,
    fleetOtpMsg: null,
    placeOrder: {},
};


function getUnique(array, key) {
    if (typeof key !== 'function') {
        const property = key;
        key = function (item) {
            return item[property];
        };
    }
    return Array.from(array.reduce(function (map, item) {
        const k = key(item);
        if (!map.has(k)) map.set(k, item);
        return map;
    }, new Map()).values());
}

const sessionReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SESSION_LOGIN: {
            return {
                ...state,
                auth: true,
                last_name: action.payload?.user_details?.last_name,
                access_token: action.payload?.access_token,
                otpSent: false,
                otpStatus: false,
                isLoading: false,
                getOtpForLoginMsg: null
            };
        }
        case actionTypes.SESSION_EXPIRED: {
            return {
                ...state,
                sessionExpired: true,
                isLoading: false,
            };
        }

        case actionTypes.SESSION_LOGOUT: {
            return {
                ...state,
                loggedIn: false,
                isLoading: false,
                auth: false,
                user: {
                    role: 'GUEST',
                    otpSent: false,
                    otpStatus: false,
                },
                verify_user: "",
                isOTP: false,
            };
        }

        case actionTypes.SESSION_LOGIN_ERROR: {
            return {
                ...state,
                loggedIn: false,
                loginFailed: true,
                otpSent: false,
                otpStatus: false,
                isLoading: false,
            };
        }
        case actionTypes.DETAILS: {
            return {
                ...state,
                detailsOfCustomer: action?.payload,
                user: {
                    customerId: action?.payload?.user_id,
                    first_name: capitalize(action?.payload?.first_name),
                    email: action?.payload?.email,
                    avatar: '/images/avatars/avatar_7.png',
                    mobile: action?.payload?.mobile,
                    all_order_count: action?.payload?.all_order_count,
                    total_qty_delivered: action?.payload?.total_qty_delivered,
                    role: 'USER',
                },
                isLoading: false,
            };
        }
        case actionTypes.UPDATE_PROFILE_SUCCESS: {
            return {
                ...state,
                loggedIn: false,
                otpStatus: false,
                updateSuccessful: true
            };
        }
        case actionTypes.UPDATE_PROFILE_ERROR: {
            return {
                ...state,
                loggedIn: false,
                otpStatus: false,
                updateSuccessful: false
            };
        }
        case actionTypes.FORGOT_PASSWORD_SUCCESS: {
            return {
                ...state,
                loggedIn: false,
                isLoading: false,
                forgotPasswordMsgSent: action?.payload,
                isForgotPasswordLoading: false
            };
        }
        case actionTypes.FORGOT_PASSWORD_FAIL: {
            return {
                ...state,
                loggedIn: false,
                isLoading: false,
                otpStatus: false,
                forgotPasswordMsgSent: action?.payload
            };
        }
        case actionTypes.LOADING: {
            return {
                ...state,
                isLoading: true,
                forgotPasswordMsgSent: action?.payload,
                referralList: [],
                referralAdd: {}
            };
        }
        case actionTypes.LOADING_FALSE: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case actionTypes.GET_OTP_FOR_LOGIN: {
            return {
                ...state,
                isLoading: false,
                otpSent: action?.payload,
                noUserFound: false,
                otpStatus: true,
                getOtpForLoginMsg: null
            };
        }
        case actionTypes.GET_OTP_FOR_LOGIN_ERROR: {
            return {
                ...state,
                isLoading: false,
                noUserFound: action?.payload,
                otpStatus: false,
            };
        }
        case actionTypes.FORGOT_LOADING: {
            return {
                ...state,
                isForgotPasswordLoading: true,
                noUserFound: action?.payload
            };
        }
        case actionTypes.GET_OTP_FOR_FP: {
            return {
                ...state,
                isLoading: false,
                otpSent: action?.payload,
                noUserFound: false,
                otpStatus: true,
            };
        }
        case actionTypes.GET_OTP_FOR_FP_ERROR: {
            return {
                ...state,
                isLoading: false,
                noUserFound: action?.payload,
                otpStatus: false,
            };
        }
        case actionTypes.GET_OTP_FOR_SIGNUP: {
            return {
                ...state,
                isLoading: false,
                signupotpSent: action?.payload,
                noUserFound: false,
                signupotpStatus: false
            }
        }
        case actionTypes.GET_OTP_FOR_SIGNUP_ERROR: {
            return {
                ...state,
                isLoading: false,
                noUserFound: action?.payload,
                signupotpStatus: false
            }
        }
        case actionTypes.VERIFY_FP_OTP: {
            return {
                ...state,
                isLoading: false,
                verifyOtp: action?.payload,
                is_fp_otp: true

            }
        }
        case actionTypes.VERIFY_FP_OTP_FAIL: {
            return {
                ...state,
                isLoading: false,
                verifyOtp: action?.payload,
                is_fp_otp: false

            }
        }
        case actionTypes.VERIFY_SIGNUP_OTP: {
            return {
                ...state,
                isLoading: false,
                verifysignupOtp: action?.payload,
                signupotpSent: false,
                isSignup: false,
                is_signup_otp: true,
                loginPass: false,
                isSignupPhone: false

            }
        }
        case actionTypes.VERIFY_SIGNUP_OTP_FAIL: {
            return {
                ...state,
                isLoading: false,
                verifysignupOtp: action?.payload,
                is_signup_otp: true,
                loginPass: false,
            }
        }
        case actionTypes.VERIFY_USER: {
            return {
                ...state,
                isLoading: false,
                verify_user: action?.payload,
                loginPass: false,
                isForgetPass: false,
            }
        }
        case actionTypes.TAB_LOGIN_PASS: {
            return {
                ...state,
                isLoading: false,
                loginPass: true,
                inputName: action?.payload,
                isOTP: false,
                isSignupPhone: false,
                getOtpForLoginMsg: null
            }
        }
        case actionTypes.TAB_OTP: {
            return {
                ...state,
                isOTP: true,
                inputName: action?.payload
            }

        }
        case actionTypes.TAB_SIGN_UP: {
            return {
                ...state,
                is_signup_otp: true,
                isSignup: false,
                loginPass: false,
                isSignupPhone: false,
            }
        }
        case actionTypes.TAB_SIGN_UP_PHONE: {
            return {
                ...state,
                isSignupPhone: true,
                isSignup: false,
                is_signup_otp: false,
            }
        }
        case actionTypes.TAB_SIGN_UP_DETAILS: {
            return {
                ...state,
                is_signup_otp: false,
                isSignup: true,
            }
        }
        case actionTypes.TAB_SIGN_UP_EMAIL_OTP: {
            return {
                ...state,
                is_signup_otp: false,
                is_signup_email_otp: true,
                isSignup: false
            }
        }
        case actionTypes.TAB_SIGNUP_PASSWORD: {
            return {
                ...state,
                isSignupPass: true,
                is_signup_otp: false,
            }
        }
        case actionTypes.TAB_FORGOT_PASSWORD: {
            return {
                ...state,
                isForgetPass: true,
                isLoginUsingPassword: true,
            }
        }
        case actionTypes.CHANGE_PASSWORD_LOADING: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case actionTypes.CHANGE_PASSWORD_SUCCESS: {
            return {
                ...state,
                loggedIn: false,
                isLoading: false,
                changePasswordSuccessMsg: action?.payload,
            };
        }
        case actionTypes.CHANGE_PASSWORD_FAIL: {
            return {
                ...state,
                loggedIn: false,
                isLoading: false,
                changePasswordErrorMsg: action?.payload,
            };
        }
        case actionTypes.UPDATE_BILLING_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                profileDetails: action?.payload,
            };
        }
        case actionTypes.UPDATE_BANK_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                profileDetails: action?.payload,
            };
        }
        case actionTypes.COMPLAINT: {
            return {
                ...state,
                isLoading: false,
                complaintList: action?.payload,
            };
        }
        case actionTypes.REFERRAL: {
            return {
                ...state,
                isLoading: false,
                referNext: action?.payload?.next,
                referralList: getUnique(state?.referralList?.concat(action?.payload?.results), "id"),
                referCount: action?.payload?.count,
            };
        }
        case actionTypes.ADD_REFERRAL: {
            return {
                ...state,
                isLoading: false,
                referralAdd: action?.payload
            };
        }
        case actionTypes.SUB_COMPLAINT: {
            return {
                ...state,
                isLoading: false,
                subcomplaintList: action?.payload,
            };
        }
        case actionTypes.COMPLAINT_LIST: {
            return {
                ...state,
                isLoading: false,
                complaint: state?.complaint?.concat(action?.payload?.results),
                complaintNext: action?.payload?.next,
                complaintCount: action?.payload?.count
            };
        }
        case actionTypes.RAISE_COMPLAINT_FAILED: {
            return {
                ...state,
                isLoading: false
            }
        }
        case actionTypes.CLEAR_COMPLAIN_DATA: {
            return {
                ...state,
                isLoading: true,
                complaint: [],
                complaintNext: "",
                complaintCount: 0,
            }
        }
        case actionTypes.GET_OTP_FOR_LOGIN_MSG: {
            return {
                ...state,
                isLoading: false,
                getOtpForLoginMsg: action?.payload
            }
        }
        case actionTypes.GET_OTP_FOR_LOGIN_MSG_CLEAR: {
            return {
                ...state,
                isLoading: false,
                getOtpForLoginMsg: null
            }
        }
        case actionTypes.REFRESH: {
            return {
                ...state,
                refresh: action?.payload,
                isLoading: false,
            };
        }
        case actionTypes.FLEET_ACCOUNT: {
            const newData = action?.payload?.results || [];
            const uniqueData = [
                ...state.fleetAccount,
                ...newData.filter(
                    (item) => !state.fleetAccount.some((existing) => existing.id === item.id)
                ),
            ];
            return {
                ...state,
                isLoading: false,
                fleetAccount: uniqueData,
                fleetAccountNext: action?.payload?.next,
                fleetAccountCount: action?.payload?.count,
            };
        }
        case actionTypes.VAN_HISTORY: {
            const newData = action?.payload?.results || [];
            const uniqueData = [
                ...state.vanHistory,
                ...newData.filter(
                    (item) => !state.vanHistory.some((existing) => existing.id === item.id)
                ),
            ];
            return {
                ...state,
                isLoading: false,
                vanHistory: uniqueData,
                vanHistoryNext: action?.payload?.next,
                vanHistoryCount: action?.payload?.count
            };
        }
        case actionTypes.CLEAR_VAN_HISTORY: {
            return {
                ...state,
                vanHistory: [],
                vanHistoryNext: null,
                vanHistoryCount: 0,
            };
        }
        case actionTypes.ADD_VAN_ACCOUNT: {
            return {
                ...state,
                isLoading: false,
                vanAccountAdd: action?.payload
            };
        }
        case actionTypes.ADD_VAN_ACCOUNT_ERROR: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case actionTypes.GET_OTP_FOR_FLEET: {
            return {
                ...state,
                isLoading: false,
                fleetOtpSent: action?.payload,
                noUserFound: false,
                otpStatus: true,
                fleetOtpMsg: null
            };
        }
        case actionTypes.GET_OTP_FOR_FLEET_MSG: {
            return {
                ...state,
                isLoading: false,
                fleetOtpMsg: action?.payload
            }
        }
        case actionTypes.GET_OTP_FOR_FLEET_ERROR: {
            return {
                ...state,
                isLoading: false,
                noUserFound: action?.payload,
                otpStatus: false,
            };
        }
        case actionTypes.VERIFY_FLEET_OTP: {
            return {
                ...state,
                isLoading: false,
                verifyFlletOtp: action?.payload,
                is_fp_otp: true

            }
        }
        case actionTypes.RESET_PAGE: {
            return {
                ...state,
                loginFailed: false,
                updateSuccessful: false,
                isLoading: false,
                forgotPasswordMsgSent: false,
                otpSent: false,
                signupotpSent: false,
                noUserFound: false,
                changePasswordSuccessMsg: null,
                changePasswordErrorMsg: null,
                isForgotPasswordLoading: false,
                otpStatus: false,
                signupotpStatus: false,
                verify_user: "",
                isOTP: false,
                isForgetPass: false,
                userData: {},
                c_code: '',
                inputName: '',
                is_fp_otp: false,
                isSignupPhone: false,
                isLoginUsingPassword: false,
                isSignup: false,
                is_signup_otp: false,
                isSignupPass: false,
                is_signup_email_otp: false,
                loginPass: false,
                subcomplaintList: [],
                referralList: [],
                complaint: [],
                sessionExpired: true,
                verifysignupOtp: null,
                complaintCount: 0,
                getOtpForLoginMsg: null,
                referCount: 0,
                referNext: '',
                referralAdd: {},
                refresh: null,
                fleetAccount: [],
                fleetAccountNext: null,
                fleetAccountCount: 0,
                vanHistory: [],
                vanHistoryNext: null,
                vanHistoryCount: 0,
                vanAccountAdd: {},
                fleetOtpSent: false,
                fleetOtpMsg: null,
                placeOrder: {},
            }
        }
        case actionTypes.VERIFY_FLEET_OTP_FAIL: {
            return {
                ...state,
                isLoading: false,
                verifyFleetOtp: action?.payload,
                is_fp_otp: false

            }
        }
        case actionTypes.PLACE_ORDER: {
            return {
                ...state,
                isLoading: false,
                placeOrder: action?.payload
            };
        }
        default: {
            return state;
        }
    }
};

export default sessionReducer;
